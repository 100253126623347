<template>
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="160" height="160" rx="80" fill="#FBFAF9" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="160"
      height="160"
    >
      <rect width="160" height="160" rx="80" fill="#D9E6F8" />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M58.7735 47.4942C58.7735 47.4942 52.2875 40.4228 59.0575 35.4785C63.5728 32.1747 67.8447 39.8721 67.8447 39.8721C67.8447 39.8721 77.7331 32.1805 73.3395 21.1965C73.3395 21.1965 93.1164 34.3773 95.3132 51.9573H86.5261C86.5261 51.9573 84.6886 67.5377 82.1325 71.7342C76.8637 80.3707 64.6045 73.8267 60.1588 71.7342C50.5602 67.2247 42.3411 83.6571 47.5287 99.1738C49.9458 106.408 58.7619 108.314 65.3523 108.314C68.233 108.314 72.7483 108.245 76.9158 108.17C82.4976 108.065 87.0767 112.569 87.0767 118.151C87.0767 118.151 87.894 135.748 87.8766 146.918"
        stroke="#001965"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M80.057 67.4797C80.057 67.4797 75.704 68.9287 72.8522 66.3958"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M56.2864 17C56.2864 17 28.36 21.8573 37.466 58.281"
        stroke="#001965"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.3232 54.0324C31.0245 54.0324 35.6463 49.4106 35.6463 43.7093C35.6463 38.0079 31.0245 33.3861 25.3232 33.3861C19.6218 33.3861 15 38.0079 15 43.7093C15 49.4106 19.6218 54.0324 25.3232 54.0324Z"
        stroke="#001965"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M139 65H118V71H139V65Z"
        stroke="#001965"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M140.784 82.2429H136.455V77.9131H133.574V82.2429H129.25V85.1236H133.574V89.4477H136.455V85.1236H140.784V82.2429Z"
        stroke="#001965"
        stroke-miterlimit="10"
      />
      <path
        d="M101.132 109.161L98.2051 109.155C97.5154 109.155 96.9589 108.558 96.9589 107.816L96.9705 96.0322C96.9821 88.2478 102.46 81.9473 109.201 81.9589C115.947 81.9705 121.402 88.2826 121.396 96.067L121.379 107.851C121.379 108.587 120.816 109.184 120.127 109.184L117.02 109.178"
        stroke="#001965"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M108.916 82.5211L109.079 64.5295C109.079 59.4114 113.675 55.2612 119.338 55.2612C125.007 55.2612 129.598 59.4114 129.598 64.5295V65.2366"
        stroke="#001965"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M102.39 113.125C103.136 113.125 103.741 111.517 103.741 109.532C103.741 107.547 103.136 105.938 102.39 105.938C101.644 105.938 101.039 107.547 101.039 109.532C101.039 111.517 101.644 113.125 102.39 113.125Z"
        stroke="#001965"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M116.041 113.125C116.787 113.125 117.391 111.517 117.391 109.532C117.391 107.547 116.787 105.938 116.041 105.938C115.295 105.938 114.69 107.547 114.69 109.532C114.69 111.517 115.295 113.125 116.041 113.125Z"
        stroke="#001965"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ImgHypoHcp',
}
</script>
